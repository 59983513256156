/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useState } from 'react';
import { MatchesProvider, useMatchesValue } from '../../context/matchesContext';
import { fetchLeagueTables } from '../../actions/matchesAction';
import MU from '../../constants/muConstants';
import Loader from '../Common/Loader';
import { withTranslation } from 'react-i18next';
import TimeFilter from '../Matches/TimeFilter';
import { getSelectedYear } from '../../utils/matchUtils';
import LeagueFilter from '../Matches/LeagueFilter';
import TeamFilter from '../Matches/TeamFilter';
import LeagueTableGrouping from './LeagueTableGrouping';
import Sidebar from '../Matches/Sidebar';
import ErrorBoundary from '../Common/ErrorBoundary';
const LeagueTable = (props) => (
  <MatchesProvider>
    <LeagueTableContainer {...props} />
  </MatchesProvider>
);

const LeagueTableContainer = (props) => {
  const sYear = getSelectedYear();
  const [selectedYear] = useState(sYear);
  const [selectedFilter] = useState('tables');
  const [selectedFilterValue, setSelectedFilterValue] = useState(MU.matchFilter);
  const [
    { tables, listingLoader, teamTypeFilters, filterGroups, resultData },
    dispatch,
  ] = useMatchesValue();

  useEffect(() => {
    fetchLeagueTables(dispatch, selectedFilterValue);
  }, []);

  const isCurrentYear = () => {
    if (!MU.currentSeason || MU.currentSeason.includes(selectedYear)) {
      return true;
    }
    return false;
  };

  const selectTeamTypeFilter = (value) => {
    if (value != 'tables') {
      if (value === 'results') {
        window.location.href =
          window.location.origin +
          window.location.pathname.replace('/league-table', '') +
          '?filter=results';
      } else {
        window.location.pathname = window.location.pathname.replace('/league-table', '');
      }
    }
  };

  return (
    <Fragment>
      <div className="main">
        <div className="matches-container">
          {filterGroups && (
            <ErrorBoundary>
              <LeagueFilter
                filterGroups={filterGroups}
                selectedYear={selectedYear}
                moduleName="leaguetable"
              />
            </ErrorBoundary>
          )}
          {teamTypeFilters && (
            <ErrorBoundary>
              <TeamFilter
                isCurrentYear={isCurrentYear}
                selectedFilter={selectedFilter}
                selectedYear={selectedYear}
                teamTypeFilters={teamTypeFilters}
                selectTeamTypeFilter={selectTeamTypeFilter}
                showTable={true}
              />
            </ErrorBoundary>
          )}
          <div className="container">
            <div className="matches-wrapper">
              <div className="matches-component">
                <div className="grid-12 matches">
                  {tables && <LeagueTableGrouping tablesData={tables} tableType="fullTable" />}
                </div>
                {filterGroups && (
                  <ErrorBoundary>
                    <TimeFilter timeFilters={filterGroups[0]} selectedYear={selectedYear} />
                  </ErrorBoundary>
                )}
              </div>
              <Sidebar resultData={resultData} />
            </div>
          </div>
        </div>
      </div>
      {listingLoader && <Loader />}
    </Fragment>
  );
};

export default withTranslation()(LeagueTable);
