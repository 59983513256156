/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { buildImgUrl } from '../../utils/utils';
import { withTranslation } from 'react-i18next';
import { leagueTableConstants } from '../../constants/leagueTableConstants';
import { manuIds } from '../../utils/matchUtils';

const TableRow = (props) => {
  const { dataItem, index, isGrouping, religationRow } = props;

  const statusclass = (position, startPosition) => {
    let gameStatus;
    if (position < startPosition) {
      gameStatus = 'up-arrow';
    } else if (position > startPosition) {
      gameStatus = 'down-arrow';
    } else if (position === startPosition) {
      gameStatus = 'neutral-arrow';
    }
    return gameStatus;
  };

  const getName = (result) => {
    if (result === 'Won') {
      return 'W';
    } else if (result === 'Draw') {
      return 'D';
    } else if (result === 'Lost') {
      return 'L';
    }
  };

  const gameStatusClassName = (result) => {
    let gameStatusClass;
    if (result === 'Won') {
      gameStatusClass = 'game-win';
    } else if (result === 'Lost') {
      gameStatusClass = 'game-loose';
    } else if (result === 'Draw') {
      gameStatusClass = 'game-draw';
    }
    return gameStatusClass;
  };

  const homeTeamName = (dataItem, index) => {
    let manchesterName = dataItem.FID;
    let showSplitted = dataItem.showSplittedLine ? dataItem.showSplittedLine : false;
    if (manuIds.includes(manchesterName) && showSplitted) {
      let className = 'fontBold';
      if (index === 3) {
        className += ' split-line';
      }
      return className;
    } else if (showSplitted) {
      if (index === 3) {
        return 'split-line';
      } else {
        return '';
      }
    } else if (manuIds.includes(manchesterName)) {
      return 'fontBold';
    }
  };

  const arrowText = statusclass(
    dataItem.TeamStandings.Position,
    dataItem.TeamStandings.StartDayPosition
  );

  return (
    <tr
      className={`table-row ${homeTeamName(dataItem, index)}
      ${religationRow ? 'religation' : ''}`}
      key={dataItem.id}
    >
      <td className="first-col">
        <span className={arrowText}></span>
        {arrowText === 'neutral-arrow' && (
          <span className="screenreader">{props.t('Positionremainsthesame')}</span>
        )}
        {arrowText === 'up-arrow' && (
          <span className="screenreader">{props.t('Positionmovedup')}</span>
        )}
        {arrowText === 'down-arrow' && (
          <span className="screenreader">{props.t('Positionmoveddown')}</span>
        )}
      </td>
      <td className="second-col bold">{dataItem.TeamStandings.Position}</td>
      <th className="third-col bo ld" scope="row">
        <div className="live-table__table___team">
          <span aria-hidden="true">
            {dataItem.TeamCrestImage && (
              <img
                className="icon-team-dummy"
                src={buildImgUrl(dataItem.TeamCrestImage.img1x)}
                alt={dataItem.TeamName}
              />
            )}
          </span>
          <span>{dataItem.ShortClubName ? dataItem.ShortClubName : dataItem.TeamName}</span>
        </div>
      </th>
      <td className="fourth-col">{dataItem.TeamStandings.Played}</td>
      <td className="fifth-col">{dataItem.TeamStandings.Won}</td>
      <td className="seven-col">{dataItem.TeamStandings.Drawn}</td>
      <td className="sixth-col">{dataItem.TeamStandings.Lost}</td>
      {!isGrouping && <td className="for-col">{dataItem.TeamStandings.For}</td>}
      {!isGrouping && <td className="against-col">{dataItem.TeamStandings.Against}</td>}
      <td className="eight-col">{dataItem.TeamStandings.For - dataItem.TeamStandings.Against}</td>
      <td className="ninth-col bold">
        <b>{dataItem.TeamStandings.Points}</b>
      </td>
      <td className="tenth-col">
        <ul className="last-games">
          {dataItem.MatchHistoryList.map((gameStatusItem, index) => (
            <li
              key={gameStatusItem.ID}
              className={gameStatusClassName(gameStatusItem.MatchResultForTeam)}
            >
              <span aria-hidden="true">{getName(gameStatusItem.MatchResultForTeam)}</span>
              <span className="screenreader">
                {props.t(
                  leagueTableConstants[
                    `${index}-col-${gameStatusItem.MatchResultForTeam.toLowerCase()}`
                  ]
                )}
              </span>
            </li>
          ))}
        </ul>
      </td>
    </tr>
  );
};

export default withTranslation()(TableRow);
