/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

 import React from 'react';
 import CookieConsent from './CookieConsent';
 import CookieInformative from './CookieInformative';
 import MU from '../../../constants/muConstants';
 const CookiePolicy = (props) => {
   const { context } = props;
   const { CookiePolicy } = context;
   return MU && MU.isCurrentGeoinConsentList ? (
     <CookieConsent {...CookiePolicy} layoutId={props.layoutId} context={context}/>
   ) : (
     <CookieInformative {...CookiePolicy} layoutId={props.layoutId} />
   );
 };
 export default React.memo(CookiePolicy);
