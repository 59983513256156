/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getMatchDate } from '../../utils/momentDate';
import { buildImgUrl, createPlayerUrl } from '../../utils/utils';
import * as track from '../../utils/analytics';
import Sponsor from '../Common/Sponsor';
import { getLineupFormation } from '../../utils/matchUtils';
import LineupPlayerImage from './LineupPlayerImage';
import { CONTENT_TYPE, THRESHOLD } from '../../constants/globalConstants';
import { MATCHDAY_LISTING } from '../../constants/containerConstants';

const LineupCard = ({ t, item }) => {
  let filterStartDataArr, timeoutId;
  let data =
    item &&
    item.lineupdata_t &&
    item.lineupdata_t.value &&
    item.lineupdata_t.value.length > 0 &&
    item.lineupdata_t.value[0];
  let matchData =
    data &&
    data.MatchDataList &&
    data.MatchDataList.length > 0 &&
    data.MatchDataList[0] &&
    data.MatchDataList[0];
  let homeTeam = matchData && matchData.HomeTeam;
  let awayTeam = matchData && matchData.AwayTeam;
  let matchDate = matchData && matchData.MatchDate;
  let competitionName = data && data.CompetitionName;
  let homeTeamName =
    homeTeam && homeTeam.ShortClubName ? homeTeam.ShortClubName : homeTeam.TeamName;
  let awayTeamName =
    awayTeam && awayTeam.ShortClubName ? awayTeam.ShortClubName : awayTeam.TeamName;
  let venue = matchData && matchData.Venue && matchData.Venue.City;
  let tabStatus = awayTeam.FID == 1 ? 'awayTeam' : 'homeTeam';
  const [activeTab, setActiveTab] = useState({ tabName: tabStatus });
  const updateView = () => {
    return typeof window !== 'undefined' && window.innerWidth < 768 ? true : false;
  };
  const [windowFlag, setWindowFlag] = useState(updateView());
  const [mainData, setMainData] = useState(null);
  const [subData, setSubData] = useState(null);
  const [formation, setFormation] = useState(null);
  const [playerPlaceHolder, setPlayerPlaceHolder] = useState(null);
  useEffect(() => {
    initLineup();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const initLineup = () => {
    let tabData = tabStatus === 'homeTeam' ? homeTeam : awayTeam;
    filterFormationOpta(tabData);
  };
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setWindowFlag(updateView());
    }, THRESHOLD);
  };
  /**
   *@method {filter of lineup formation of data}
   *@param {conditional parameter of hometeam data or away team data}
   */
  const filterFormationOpta = (dataFormation) => {
    try {
      let formateType;
      let formationObj = getLineupFormation();
      let formationUsed = formationObj[dataFormation.FormationUsed];
      if (typeof formationUsed != undefined && formationUsed != 'undefined' && formationUsed) {
        formateType = formationUsed;
      } else {
        formateType = formationObj[442];
      }
      let teamData = dataFormation['TeamPlayers'].slice();

      filterStartDataArr = new Array();
      let filterSubDataArr = new Array();
      let playerPlaceHolderArray = [];
      for (let i = 0; i < formateType.length; i++) {
        for (let j = 0; j < teamData.length; j++) {
          if (teamData[j].Player && teamData[j].Player.hasOwnProperty('FormationPlace')) {
            if (
              formateType[i] == teamData[j].Player.FormationPlace &&
              teamData[j].Status === 'Start'
            ) {
              filterStartDataArr.push(teamData[j]);
              playerPlaceHolderArray.push(teamData[j]);
            } else if (teamData[j].Status === 'Sub') {
              filterSubDataArr.push(teamData[j]);
              teamData.splice(j, 1);
              j--;
            }
          } else if (teamData[j].hasOwnProperty('Status')) {
            if (
              formateType[i] == teamData[j].Player.FormationPlace &&
              teamData[j].Status === 'Start'
            ) {
              filterStartDataArr.push(teamData[j]);
              playerPlaceHolderArray.push(teamData[j]);
              teamData.splice(j, 1);
            } else if (teamData[j].Status === 'Sub') {
              filterSubDataArr.push(teamData[j]);
              teamData.splice(j, 1);
              j--;
            }
          }
          setSubData(filterSubDataArr);
          setPlayerPlaceHolder(playerPlaceHolderArray);
        }

        if (i == formateType.length - 1) {
          filterDataOpta(dataFormation);
        }
      }
    } catch (e) {
      console.log('error in lineup data', e);
    }
  };

  let filterDataOpta = (dataFormation) => {
    try {
      let formationId = dataFormation.FormationUsed;
      setFormation(formationId ? formationId.split('').join(' ') : '');
      let mainDataArr = [];
      let jsondata = filterStartDataArr;
      let obj = {};
      let lineupObj = {};
      let playerPlaceHolderData = [];
      obj[formationId] = [];
      playerPlaceHolderData.push(jsondata.shift());
      lineupObj.playerPlaceHolder = playerPlaceHolderData;
      obj[formationId].push(lineupObj);
      mainDataArr.push(lineupObj);

      for (let i = 0; i < formationId.length; i++) {
        lineupObj = {};
        playerPlaceHolderData = [];
        for (let j = 0; j < formationId[i]; j++) {
          playerPlaceHolderData.push(jsondata.shift());
          if (j == formationId[i] - 1) {
            lineupObj.playerPlaceHolder = playerPlaceHolderData;
            obj[formationId].push(lineupObj);
            mainDataArr.push(lineupObj);
          }
        }
      }
      setMainData(mainDataArr);
    } catch (e) {
      console.log('error in lineup data', e);
    }
  };

  /**
   *
   * @param {tracking of lineup feed card tab} tabName
   */
  const trackCard = (tabName) => {
    const data = track.data('card');
    track.analytics({
      ...data,
      card_name: `${homeTeamName} ${t('VS')} ${awayTeamName}`,
      container_type: 'matchday-listing',
      item_id: item.itemid_s,
      content_type: item.contenttype_t,
      tag: item.taglist_sm,
      created_datetime: item.appearance_tdt,
      author: item.authornameinfo_s,
      destination_url: item.destinationurl_s,
    });
  };

  /**
   *
   * @param {switch between tabs and active tab. tabname may be homeTeam/awayTeam} tabName
   */
  const teamTab = (tabName) => {
    setActiveTab({ tabName: tabName });
    trackCard(tabName === 'homeTeam' ? 'homeTeam' : 'awayTeam');
    let tabData = tabName === 'homeTeam' ? homeTeam : awayTeam;
    filterFormationOpta(tabData);
  };
  const teamTabKeyPressed = (e, teamFlag) => {
    let keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      teamTab(teamFlag);
    }
  };
  /**
   *
   * @method {Diplay tabs}
   */
  const drawTabs = () => {
    return (
      <ul className="lineup__tab" role="tablist" aria-label={t('lineup')}>
        <li
          className={`team__tab1 ${activeTab.tabName === 'homeTeam' ? 'active' : ''}`}
          onClick={(e) => teamTab('homeTeam')}
          onKeyPress={(e) => teamTabKeyPressed(e, 'homeTeam')}
          role="tab"
          aria-selected={activeTab.tabName === 'homeTeam' ? true : false}
          style={{ opacity: activeTab.tabName === 'homeTeam' ? 1 : 0.4 }}
          tabIndex="0"
          aria-label={homeTeam.TeamName}
        >
          <span className="teamA-img" aria-hidden="true">
            <img
              src={
                homeTeam && homeTeam.TeamCrestImage && buildImgUrl(homeTeam.TeamCrestImage.img1x)
              }
              alt={homeTeam && homeTeam.TeamName}
            />
          </span>
          <span className="teamA-name" aria-hidden="true">
            <span className="team-heading">{homeTeamName}</span>
          </span>
        </li>
        <li
          className={`team__tab2 ${activeTab.tabName === 'awayTeam' ? 'active' : ''}`}
          role="tab"
          aria-selected={activeTab.tabName === 'awayTeam' ? true : false}
          aria-label={awayTeam.TeamName}
          tabIndex="0"
          onClick={(e) => teamTab('awayTeam')}
          onKeyPress={(e) => teamTabKeyPressed(e, 'awayTeam')}
          style={{ opacity: activeTab.tabName === 'awayTeam' ? 1 : 0.4 }}
        >
          <span className="teamB-img" aria-hidden="true">
            <img
              src={
                awayTeam && awayTeam.TeamCrestImage && buildImgUrl(awayTeam.TeamCrestImage.img1x)
              }
              alt={awayTeam && awayTeam.TeamName}
            />
          </span>
          <span className="teamB-name" aria-hidden="true">
            <span className="team-heading">{awayTeamName}</span>
          </span>
        </li>
      </ul>
    );
  };

  return (
    <React.Fragment>
      {item && item.lineupdata_t && item.lineupdata_t.value.length && (
        <div className="grid-12">
          <span className="screenreader">{t('lineup')}</span>
          <div className="match-day lineup child">
            <Sponsor
              contentType={item.contenttype_t}
              varient={'child'}
              sponsorInfo={item.sponsordetailinfo_s}
              grid={'grid-12 novarient'}
              type={CONTENT_TYPE}
              author={item.authornameinfo_s}
              cardName={`${homeTeamName} ${t('VS')} ${awayTeamName}`}
              container={MATCHDAY_LISTING}
              createdDateTime={item.__published_tdt}
              itemId={item.itemid_s}
              tags={item.taglist_sm}
            ></Sponsor>
            <ul className="lineup__time_line">
              {data && data.DarkBgLogo && data.DarkBgLogo.img1x && (
                <li title="competition Name">
                  <img src={buildImgUrl(data.DarkBgLogo.img1x)} alt={competitionName} />
                </li>
              )}
              <li>{getMatchDate(matchDate, 'dddd D MMMM yyyy')}</li>
              {matchDate && (
                <li>
                  {`${t('KickOff')} ${getMatchDate(matchDate, 'HH:mm')}`}
                  {venue && <span>{`, ${venue}`}</span>}
                </li>
              )}
            </ul>
            {drawTabs()}
            <div className="lineup__team">
              <div className="lineup__team-members">
                <span className="screenreader">{`${t(
                  'startingelevenplayerformation'
                )} ${formation}`}</span>
                <ul className="lineup__team-count-text">
                  <li aria-hidden="true">{t('starting').toUpperCase()}</li>
                  <li aria-hidden="true">{t('xi').toUpperCase()}</li>
                </ul>
                {!windowFlag && <div className="golkeeper_place"></div>}
                {!windowFlag && (
                  <ul className="teamFormation">
                    {mainData &&
                      mainData.length > 0 &&
                      mainData.map((lineupItem, key) => (
                        <li key={key}>
                          {lineupItem.playerPlaceHolder.map((playerInfo, index) => (
                            <div className="lineup__team-icons" key={index}>
                              <span className="player-id">{playerInfo.JerseyNumber}</span>
                              {playerInfo.Player && playerInfo.Player.FID && (
                                <span>
                                  {playerInfo.Player.PlayerTag ? (
                                    <a
                                      href={createPlayerUrl(playerInfo.Player, 'href')}
                                      aria-label={
                                        playerInfo.Player.KnownName
                                          ? playerInfo.Player.KnownName
                                          : playerInfo.Player.LastName
                                      }
                                    >
                                      <LineupPlayerImage
                                        item={playerInfo}
                                        shirtDetail={false}
                                        label={false}
                                        access={false}
                                      />
                                    </a>
                                  ) : (
                                    <LineupPlayerImage
                                      item={playerInfo}
                                      shirtDetail={false}
                                      label={true}
                                      access={false}
                                    />
                                  )}
                                </span>
                              )}
                              {!playerInfo.Player.FID && (
                                <span>
                                  <img className="avatar" src="" title=" " alt="avtar image" />
                                </span>
                              )}
                              {playerInfo.Player.PlayerTag ? (
                                <p className="player-name test1" tabIndex="-1" aria-hidden="true">
                                  {playerInfo.Player.KnownName
                                    ? playerInfo.Player.KnownName
                                    : playerInfo.Player.LastName}
                                </p>
                              ) : (
                                <p className="player-name test1">
                                  {playerInfo.Player.KnownName
                                    ? playerInfo.Player.KnownName
                                    : playerInfo.Player.LastName}
                                </p>
                              )}
                            </div>
                          ))}
                        </li>
                      ))}
                  </ul>
                )}
                {!windowFlag && (
                  <div className="ground_halfCircle">
                    <div></div>
                  </div>
                )}
                {windowFlag && (
                  <ul className="teamFormation">
                    <li>
                      {playerPlaceHolder &&
                        playerPlaceHolder.length > 0 &&
                        playerPlaceHolder.map((playerInfo, index) => (
                          <div key={index} className="lineup__team-icons">
                            {/* <!--if PlayerTag is available, used in mobile Layout--> */}
                            {playerInfo.Player && playerInfo.Player.PlayerTag && (
                              <div className="lineup__team-info">
                                <a
                                  href={createPlayerUrl(playerInfo.Player, 'href')}
                                  aria-label={`${t('Shirtnumber')} ${playerInfo.JerseyNumber} ${
                                    playerInfo.Player.KnownName
                                      ? playerInfo.Player.KnownName
                                      : playerInfo.Player.LastName
                                  }`}
                                >
                                  <span className="player-id" tabIndex="-1" aria-hidden="true">
                                    {playerInfo.JerseyNumber}
                                  </span>
                                  {playerInfo.Player.FID ? (
                                    <span>
                                      <LineupPlayerImage
                                        item={playerInfo}
                                        shirtDetail={false}
                                        label={false}
                                        access={false}
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      <img className="avatar" src="" alt="avtar image" title="" />
                                    </span>
                                  )}

                                  <p className="player-name" tabIndex="-1" aria-hidden="true">
                                    {playerInfo.Player.KnownName
                                      ? playerInfo.Player.KnownName
                                      : playerInfo.Player.LastName}
                                  </p>
                                </a>
                              </div>
                            )}
                            {/* <!--if PlayerTag is not available,used in mobile Layout --> */}
                            {!playerInfo.Player.PlayerTag && (
                              <div>
                                <span className="player-id" aria-hidden="true">
                                  {playerInfo.JerseyNumber}
                                </span>
                                {playerInfo.Player.FID ? (
                                  <span>
                                    <LineupPlayerImage
                                      item={playerInfo}
                                      shirtDetail={true}
                                      label={false}
                                      access={true}
                                    />
                                  </span>
                                ) : (
                                  <span>
                                    <img className="avatar" src="" alt="avtar image" title="" />
                                  </span>
                                )}

                                <p className="player-name" tabIndex="0" aria-hidden="true">
                                  {playerInfo.Player.KnownName
                                    ? playerInfo.Player.KnownName
                                    : playerInfo.Player.LastName}
                                </p>
                              </div>
                            )}
                          </div>
                        ))}
                    </li>
                  </ul>
                )}
              </div>
              <ul className="lineup__sub-player">
                <li>
                  <span className="screenreader">{t('Substitutes')}</span>
                  <span aria-hidden="true">{`${t('Subs')} `}</span>
                  {subData &&
                    subData.length > 0 &&
                    subData.map((sub, index) => (
                      <span
                        key={index}
                        title={
                          sub.Player && sub.Player.KnownName
                            ? sub.Player.KnownName
                            : sub.Player.LastName
                        }
                      >
                        {sub.Player && sub.Player.KnownName
                          ? sub.Player.KnownName
                          : sub.Player.LastName}
                        {subData.length !== index + 1 && <small>, </small>}
                      </span>
                    ))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(LineupCard);
