/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { fetchedGigya } from '../../actions/sponsorAction';
import MU from '../../constants/muConstants';
const GigyaInit = ({ dispatch }) => {
  const getGigyaUrl = () => {
    if (MU.language !== 'zh') {
      return `https://cdns.gigya.com/js/gigya.js?apiKey=${MU.gigyaAPiKey}&lang=${MU.language}&v=${MU.buildRevision}`;
    } else {
      return `https://cdns.gigya.com/js/gigya.js?apiKey=${MU.gigyaAPiKey}&lang=${MU.language}-cn&v=${MU.buildRevision}`;
    }
  };
  useEffect(() => {
    var v = document.createElement('script');
    v.src = getGigyaUrl();
    document.body.appendChild(v);
    v.onload = () => getGigya();
  }, []);

  const getGigya = () => {
    fetchedGigya(dispatch);
  };

  return null;
};

export default GigyaInit;
