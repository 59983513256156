/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ACCOUNT_SUBSCRIPTION, REGISTERED } from '../../constants/globalConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import { getContentAccessLocal } from '../../utils/utils';
import MU from '../../constants/muConstants';

export const AccessBadge = ({
  contentAcessLocal,
  requiredInList,
  className,
  contentTypeLocal,
  tagListLocal,
  withAnchor,
  isSearchPage,
  isEPG,
  item,
  t,
}) => {
  const [{ isLoginChecked, isUserLogin, isUserSubscribe }] = useSponsorValue();
  const subscriberTag = () => {
    return withAnchor ? (
      <a className={isEPG ? 'top-badges' : 'bottom-badges'} href="javascript:void(0)">
        {subscriberElm()}
      </a>
    ) : (
      subscriberElm()
    );
  };
  const subscriberElm = () => {
    if (isSearchPage) {
      return (
        <div className="subscribe-watch-search">
          <span className="icon-subscription-gold-epg"></span>
          <span className="sub-text">{t('Subscribe')}</span>
        </div>
      );
    }
    return (
      <div className="subscribe-watch">
        <span className="icon-subscribe-watch"></span>
        <span className="sub-text" {...updateAttributes()}>
          {t('Subscribe')}
        </span>
        {isEPG && (
          <span className="screenreader">
            {t('Subscribe')}, {item && item.schedulemetadata_t && item.schedulemetadata_t.Title}
          </span>
        )}
      </div>
    );
  };
  const registerTag = () => {
    return withAnchor ? (
      <div className={isEPG ? 'top-badges' : 'bottom-badges'}>{registerElm()}</div>
    ) : (
      registerElm()
    );
  };
  const updateAttributes = () => {
    if (isEPG) {
      return { 'aria-hidden': 'true' };
    }
    return {};
  };
  const registerElm = () => {
    if (isSearchPage) {
      return (
        <div className="register-watch-search">
          <span className="icon-register-icon-epg"></span>
          <span className="sub-text">{t('Register')}</span>
        </div>
      );
    }
    return (
      <div className="register-watch">
        <span className="icon-register-watch"></span>
        <span className="icon-red-icon-gold"></span>
        <span className="sub-text" {...updateAttributes()}>
          {t('Register')}
        </span>
        {isEPG && (
          <span className="screenreader">
            {t('Register')}, {item && item.schedulemetadata_t && item.schedulemetadata_t.Title}
          </span>
        )}
      </div>
    );
  };

  const getAcessBadge = () => {
    if (MU.isIDMEnabled) {
      const finalContentAccess = getContentAccessLocal(
        contentAcessLocal,
        contentTypeLocal,
        tagListLocal
      );
      if (!isUserLogin && finalContentAccess === REGISTERED) {
        if (requiredInList) {
          return <li className={className}>{registerTag()}</li>;
        }
        return registerTag();
      } else if (!isUserSubscribe && finalContentAccess === ACCOUNT_SUBSCRIPTION) {
        if (requiredInList) {
          return <li className={className}>{subscriberTag()}</li>;
        }
        return subscriberTag();
      }
      return null;
    } else if (isLoginChecked) {
      //reducer status that login is checked
      // use login status is being
      const finalContentAccess = getContentAccessLocal(
        contentAcessLocal,
        contentTypeLocal,
        tagListLocal
      );
      if (!isUserLogin && finalContentAccess === REGISTERED) {
        if (requiredInList) {
          return <li className={className}>{registerTag()}</li>;
        }
        return registerTag();
      } else if (!isUserSubscribe && finalContentAccess === ACCOUNT_SUBSCRIPTION) {
        if (requiredInList) {
          return <li className={className}>{subscriberTag()}</li>;
        }
        return subscriberTag();
      }
      return null;
    }
    //subscirption journey has to be done later.
    return null;
  };
  return getAcessBadge();
};

AccessBadge.defaultProps = {
  contentAcessLocal: null,
  contentTypeLocal: null,
  tagListLocal: null,
  requiredInList: false,
  isSearchPage: false,
};

export default withTranslation()(AccessBadge);
