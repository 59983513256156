/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { getAssetUrlJpeg, createBaseUrl, getRandomId } from '../../utils/utils';

const LineupPlayerImage = (props) => {
  const { Player, JerseyNumber } = props.item;
  const stgPlayerAssetUrl = getAssetUrlJpeg() + '/headShots/p';
  const [avatarErrorSrc, setAvatarErrorSrc] = useState('');
  const onErrorImg = (e) => {
    setAvatarErrorSrc(`${createBaseUrl()}/assets/images/bg/avatar.png`);
  };
  return (
    <img
      className="avatar"
      id={getRandomId()}
      src={avatarErrorSrc !== '' ? avatarErrorSrc : `${stgPlayerAssetUrl}${Player.FID}.jpg`}
      alt={
        props.shirtDetail
          ? `Shirtnumber ${JerseyNumber} ${Player.KnownName ? Player.KnownName : Player.LastName}`
          : `image of ${Player.FirstName} ${Player.LastName}`
      }
      aria-label={props.label ? `${Player.FirstName} ${Player.LastName}` : ''}
      tabIndex={props.access ? '' : '-1'}
      aria-hidden={props.access ? '' : 'true'}
      title={props.shirtDetail ? '' : `${Player.FirstName} ${Player.LastName}`}
      onError={(e) => onErrorImg(e)}
    />
  );
};

export default LineupPlayerImage;
