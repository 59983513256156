/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useState } from 'react';
import LeagueTable from './LeagueTable';
import { sortBy } from 'lodash';
import { buildImgUrl } from '../../utils/utils';
import Sponsor from '../Common/Sponsor';
import { CONTENT_TYPE } from '../../constants/globalConstants';
import { manuIds } from '../../utils/matchUtils';
import ErrorBoundary from '../Common/ErrorBoundary';

const LeagueTableGrouping = ({ tablesData, tableType }) => {
  const [tables, setTables] = useState([]);
  useEffect(() => {
    if (tablesData) {
      const orderedTables = reOrderTables(tablesData);
      setTables(orderedTables);
    }
  }, [tablesData]);

  const reOrderTables = (data) => {
    try {
      if (data.length == 2) {
        const firstTeam = data[0].optacontent.value[0].FCompetitionID;
        // 6 stands for Europa league
        if (firstTeam === '6') {
          data.reverse();
        }
      }
    } catch (e) {
      console.error('Error reversing tables data', e);
    }
    return data;
  };

  const groupingRequired = (cId) => {
    const groupingArray = ['5', '6', '604', '568', '893', '671', '742', '678', '7'];
    return groupingArray.includes(cId);
  };

  const filterResult = function (optacontent) {
    if (
      optacontent &&
      optacontent.value &&
      optacontent.value[0].Teams &&
      optacontent.value[0].Teams.length > 0
    ) {
      let groupData = false;
      const competitionId = optacontent.value[0].FCompetitionID;
      // 6 stands for Europa league
      if (groupingRequired(competitionId)) {
        groupData = true;
      }
      let teams = optacontent.value[0].Teams;
      teams = sortBy(teams, (o) => o.TeamStandings.Position);
      let newTeamsList = [];
      if (tableType !== 'fullTable') {
        if (groupData) {
          newTeamsList = groupTeams(teams, 'small');
        } else {
          const manchesterPosition = teams.findIndex((o) => manuIds.includes(o.FID));
          if (manchesterPosition >= 7) {
            // push first four
            for (var i = 0; i < 4; i++) {
              newTeamsList.push(teams[i]);
            }
            if (manchesterPosition === teams.length - 1) {
              teams[3].showSplittedLine = true;
              newTeamsList.push(teams[manchesterPosition - 2]);
              newTeamsList.push(teams[manchesterPosition - 1]);
              newTeamsList.push(teams[manchesterPosition]);
            } else {
              teams[3].showSplittedLine = true;
              newTeamsList.push(teams[manchesterPosition - 1]);
              newTeamsList.push(teams[manchesterPosition]);
              newTeamsList.push(teams[manchesterPosition + 1]);
            }
          } else {
            newTeamsList = teams.slice(0, 7);
          }
        }
      } else {
        if (groupData) {
          newTeamsList = groupTeams(teams);
        } else {
          newTeamsList = teams;
        }
      }
      return newTeamsList;
    }
  };

  const groupTeams = function (teams, type) {
    let groupedTeams = {};
    let muGrp;
    if (teams && teams.length > 0) {
      if (type === 'small') {
        const manchesterTeam = teams.find((o) => manuIds.includes(o.FID));
        if (
          manchesterTeam &&
          manchesterTeam.TeamStandings &&
          manchesterTeam.TeamStandings.GroupName
        ) {
          muGrp = manchesterTeam.TeamStandings.GroupName;
        } else {
          console.error('Group name not present');
        }
      }

      for (let k = 0; k < teams.length; k++) {
        let team = teams[k];

        if (team && team.TeamStandings && team.TeamStandings.GroupName) {
          const grpName = team.TeamStandings.GroupName;
          if (grpName) {
            if (type === 'small') {
              if (grpName === muGrp) {
                if (groupedTeams[grpName]) {
                  groupedTeams[grpName].push(team);
                } else {
                  groupedTeams[grpName] = [];
                  groupedTeams[grpName].push(team);
                }
              }
            } else {
              if (groupedTeams[grpName]) {
                groupedTeams[grpName].push(team);
              } else {
                groupedTeams[grpName] = [];
                groupedTeams[grpName].push(team);
              }
            }
          }
        }
      }
    }
    return groupedTeams;
  };

  const renderLogoAndSponsor = (item) => {
    const logo =
      item.optacontent &&
      item.optacontent.value &&
      item.optacontent.value[0] &&
      item.optacontent.value[0].DarkBgLogo &&
      item.optacontent.value[0].DarkBgLogo.img1x
        ? item.optacontent.value[0].DarkBgLogo.img1x
        : '';
    return (
      <Fragment>
        <Sponsor
          contentType={item.contenttype_t}
          varient="child"
          sponsorInfo={item.sponsordetailinfo_s}
          grid="grid-6"
          type={CONTENT_TYPE}
        />
        <div className="live-table__premierLeague">
          {logo && (
            <figure className="live-table__blog__logo">
              <img
                src={buildImgUrl(logo)}
                id={item.itemid_s}
                alt={item.optacontent.value[0].CompetitionName}
              />
            </figure>
          )}
        </div>
      </Fragment>
    );
  };
  const addClassLastData = tableType === 'smallTable' ? '' : 'last-data';
  return tables.map((item, i) => {
    const compId =
      item.optacontent &&
      item.optacontent.value &&
      item.optacontent.value[0] &&
      item.optacontent.value[0].FCompetitionID;
    const groupResult = filterResult(item.optacontent);
    if (groupingRequired(compId) && groupResult) {
      const getKeys = Object.keys(groupResult);
      const renderTables =
        getKeys &&
        getKeys &&
        getKeys.length > 0 &&
        getKeys.sort().map((key, index) => (
          <ErrorBoundary>
            <LeagueTable
              key={index}
              item={item}
              groupItem={groupResult[key]}
              tableType={tableType}
              groupName={key}
            />
          </ErrorBoundary>
        ));
      if (getKeys.length) {
        return (
          <div key={i} className="match-day live-table">
            {renderLogoAndSponsor(item)}
            {renderTables}
          </div>
        );
      }
      return null;
    } else if (groupResult) {
      return (
        <div key={i} className={`match-day live-table ${addClassLastData}`}>
          {renderLogoAndSponsor(item)}
          <ErrorBoundary>
            <LeagueTable item={item} groupItem={groupResult} tableType={tableType} />
          </ErrorBoundary>
        </div>
      );
    } else {
      return null;
    }
  });
};

export default LeagueTableGrouping;
