/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_SPONSOR,
  SET_SPONSOR,
  ERROR_SPONSOR,
  FETCHED_GIGYA,
  SET_USER_STATUS,
  SET_SPONSOR_ON_RESIZE,
  SET_HELP_RESULTS,
  SET_DEVICE_MANAGE,
  CONTINUE_ON_OTHER,
  PLAY_HERE,
  SET_MUTV_HERO_VIDEO_STATUS,
  RESET_USER_FAV_PLAYER,
} from '../constants/actions/actionConstant';
import {
  FILTER,
  CONTENT_TYPE,
  BLOG_EVENT,
  MODULE,
  ANONYMOUS,
  REGISTERED,
  ACCOUNT_SUBSCRIPTION,
  WV_REGISTERED,
  WV_SUBSCRIPTION,
} from '../constants/globalConstants';
import { checkSubscription, appUserLoginDetail } from '../utils/utils';
export const sponsorState = {
  status: null,
  sponsorData: null,
  isGigyaAvilable: null,
  userLoginDetail: null,
  isUserLogin: null,
  isLoginChecked: null,
  isUserSubscribe: null,
  isUserSkyId: null,
  showHero: true,
  deviceInfo: null,
  deviceSynclimitReached: false,
  continueOnOther: false,
  playHere: false,
  isMutvHeroVideoPlaying: { value: false },
  userConsent: null,
  onlySponsor: null,
};

/**
 * @define {returned filtered sponsor data as per there State}
 * @param {initial state} state
 * @param {Action type} type
 * @param {response} response data
 */

export const sponsorReducer = (
  state = sponsorState,
  { type, response, showHero, isMutvHeroVideoPlaying } = {}
) => {
  switch (type) {
    case 'SET_USER_CONSENT': {
      return { ...state, userConsent: response };
    }
    case 'SET_MUTV_HERO_DATA': {
      return { ...state, showHero: showHero };
    }
    case FETCH_SPONSOR:
      return { ...sponsorState, status: 'fetching' };
    case SET_SPONSOR: {
      let sponsor;
      let statsSponsor;
      if (response && response.data) {
        setStorage(response.data);
        sponsor = filterSposnsorData(response.data);
        statsSponsor = mapStatsModuleSponsor(response.data);
      }
      return {
        ...state,
        status: 'fetched',
        sponsorData: sponsor,
        onlySponsor: statsSponsor,
      };
    }
    case SET_SPONSOR_ON_RESIZE: {
      return { ...state, sponsorData: state.sponsorData, onlySponsor: state.onlySponsor };
    }
    case ERROR_SPONSOR: {
      return { ...state, status: 'error', sponsorData: null };
    }
    case FETCHED_GIGYA: {
      return { ...state, isGigyaAvilable: true };
    }
    case RESET_USER_FAV_PLAYER: {
      return {
        ...state,
        userLoginDetail: {
          ...state.userLoginDetail,
          data: {
            ...state.userLoginDetail.data,
            favoritePlayerID: '',
          },
        },
      };
    }
    case SET_USER_STATUS: {
      return {
        ...state,
        userLoginDetail: response,
        isUserLogin: response ? true : false,
        isLoginChecked: true,
        isUserSubscribe: checkSubscription(response),
        isUserSkyId: response && response.data && response.data.SkyID,
      };
    }
    case SET_HELP_RESULTS: {
      let accessValue;
      if (response) {
        if (response.helpAcL === WV_REGISTERED) {
          accessValue = REGISTERED;
        } else if (response.helpAcL === WV_SUBSCRIPTION) {
          accessValue = ACCOUNT_SUBSCRIPTION;
        } else {
          accessValue = ANONYMOUS;
        }
      }
      const isRegistered = [REGISTERED, ACCOUNT_SUBSCRIPTION].includes(accessValue) ? true : false;
      return {
        ...state,
        isLoginChecked: true,
        isUserLogin: isRegistered,
        userLoginDetail: appUserLoginDetail(accessValue, response, isRegistered),
        isUserSubscribe: accessValue === ACCOUNT_SUBSCRIPTION,
      };
    }
    case SET_MUTV_HERO_VIDEO_STATUS: {
      return { ...state, isMutvHeroVideoPlaying: isMutvHeroVideoPlaying };
    }

    //Device Management part
    case SET_DEVICE_MANAGE: {
      const deviceSynclimitReached = response && response.deviceSynclimitReached;
      const deviceInfo = response && response.deviceInfo;
      return {
        ...state,
        status: 'fetched',
        deviceSynclimitReached: deviceSynclimitReached,
        deviceInfo: deviceInfo,
        continueOnOther: false,
      };
    }
    case CONTINUE_ON_OTHER:
      return { ...state, continueOnOther: response };
    case PLAY_HERE:
      return { ...state, playHere: response };
    default:
      return state;
  }
};

/**
 * @define {return all contentType/Module/Filter sponsors after filtering}
 * @param {Takes all data which gets from Reducer} response
 */
const filterSposnsorData = (response) => {
  let contentSponsor = mapContentSponsor(response);
  let moduleSponsor = mapModuleSponsor(response);
  return { ...contentSponsor, ...moduleSponsor };
};

/**
 * @define {loop through the modulemapping groupValue to get all ModuleType sponsors and return it}
 * @param {All data which has been passed from filterSposnsorData()} resposnse
 */

const mapModuleSponsor = (resposnse) => {
  if (
    resposnse.ModuleSponsorResponse &&
    resposnse.ModuleSponsorResponse.grouped &&
    resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t &&
    resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t.groups &&
    resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t.groups.length > 0
  ) {
    let groups = resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t.groups;
    let moduleSponsors = {};
    groups.forEach((group) => {
      if (
        group &&
        group.groupValue &&
        group.doclist &&
        group.doclist.docs &&
        group.doclist.docs.length > 0
      ) {
        let groupValue = group.groupValue;
        let groupDocs = group.doclist.docs;
        let groupSponsors = [];
        groupDocs.forEach((groupDoc) => {
          if (groupDoc && groupDoc.sponsordetailinfo_s) {
            let sponsorDetail = groupDoc.sponsordetailinfo_s;
            if (sponsorDetail && sponsorDetail.length > 0) {
              groupSponsors.push(sponsorDetail[0]);
            }
          }
        });
        if (groupSponsors.length > 0) {
          moduleSponsors[groupValue] = groupSponsors;
        }
      }
    });
    return { [MODULE]: moduleSponsors };
  }
};

const mapStatsModuleSponsor = (resposnse) => {
  if (
    resposnse.ModuleSponsorResponse &&
    resposnse.ModuleSponsorResponse.grouped &&
    resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t &&
    resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t.groups &&
    resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t.groups.length > 0
  ) {
    let groups = resposnse.ModuleSponsorResponse.grouped.modulemappingtype_t.groups;
    let SeasonMatchSponsors = [];
    let matchstats = groups.filter((group) => {
      return (
        group.groupValue.toLowerCase() === 'matchstats' ||
        group.groupValue.toLowerCase() === 'seasonstats'
      );
    });
    if (matchstats) {
      matchstats.map((group) => {
        let groupDocs = group.doclist.docs;
        groupDocs.forEach((groupDoc) => {
          if (groupDoc && groupDoc.sponsordetailinfo_s) {
            let sponsorDetail = groupDoc.sponsordetailinfo_s;
            if (sponsorDetail && sponsorDetail.length > 0) {
              SeasonMatchSponsors.push(sponsorDetail[0]);
            }
          }
        });
      });
    }
    return SeasonMatchSponsors;
  }
};

/**
 * @define {Takes response and filter the sponsors response as per there CONTENT_TYPE, BLOG_EVENT, FILTER and return all in a single Object "contentSponsorObj"}
 * @param {All data which has been passed from filterSposnsorData()} resposnse
 */

const mapContentSponsor = (resposnse) => {
  if (
    resposnse.ContentSponsorResponse &&
    resposnse.ContentSponsorResponse.grouped &&
    resposnse.ContentSponsorResponse.grouped.sponsormappingtype_t &&
    resposnse.ContentSponsorResponse.grouped.sponsormappingtype_t.groups &&
    resposnse.ContentSponsorResponse.grouped.sponsormappingtype_t.groups.length > 0
  ) {
    let groups = resposnse.ContentSponsorResponse.grouped.sponsormappingtype_t.groups;
    let contentSponsorObj = {};
    groups.forEach((group) => {
      if (group && group.groupValue && group.doclist && group.doclist.docs) {
        let groupValue = group.groupValue;
        if (groupValue === FILTER) {
          contentSponsorObj[FILTER] = parseFilters(group.doclist.docs);
        } else if (groupValue === CONTENT_TYPE) {
          contentSponsorObj[CONTENT_TYPE] = parseContentType(group.doclist.docs);
        } else if (groupValue === BLOG_EVENT) {
          contentSponsorObj[BLOG_EVENT] = paseBlogEvents(group.doclist.docs);
        }
      }
    });
    return contentSponsorObj;
  }
};

/**
 *  @define {loop through the doclist and get all conetentType sponsors and return values in Objet to mapContentSponsor function}
 *  @param {Array data which has been passed from mapContentSponsor() to filter} docsList
 */

const parseContentType = (docsList) => {
  if (docsList && docsList.length > 0) {
    let contentTypeSponsors = {};
    docsList.forEach((doc) => {
      if (doc && doc.sponsordetailinfo_s && doc.sponsordetailinfo_s.length > 0) {
        let contentType = doc.mappedcontenttype_t;
        contentTypeSponsors[contentType] = doc.sponsordetailinfo_s[0];
      }
    });
    return contentTypeSponsors;
  }
};

/**
 * @define {loop through the doclist and get all blogEventType sponsors and return  values in Objet to mapContentSponsor function}
 * @param {Array data which has been passed from mapContentSponsor()} docsList
 */

const paseBlogEvents = (docsList) => {
  if (docsList && docsList.length > 0) {
    let blogEventSponsors = {};
    docsList.forEach((doc) => {
      if (doc && doc.sponsordetailinfo_s && doc.sponsordetailinfo_s.length > 0) {
        let blogEventType = doc.mappedblogevent_t;
        blogEventSponsors[blogEventType] = doc.sponsordetailinfo_s[0];
      }
    });
    return blogEventSponsors;
  }
};

/**
 *@define {loop through the doclist and get all filtersponsors and return values in Objet to mapContentSponsor function}
 * @param {Array data which has been passed from mapContentSponsor()} docsList
 */
const parseFilters = (docsList) => {
  if (docsList && docsList.length > 0) {
    let filterSponsors = {};
    docsList.forEach((doc) => {
      if (doc && doc.sponsordetailinfo_s && doc.sponsordetailinfo_s.length > 0) {
        let filterType = doc.mappedfilter_t ? doc.mappedfilter_t.toLowerCase() : '';
        let screen = doc.mappedscreen_t ? doc.mappedscreen_t.toLowerCase() : '';
        let key = `${screen}#${filterType}`;
        filterSponsors[key] = doc.sponsordetailinfo_s[0];
      }
    });
    return filterSponsors;
  }
};

const setStorage = (sponsorObj) => {
  try {
    if (typeof Storage !== 'undefined' && !sessionStorage.getItem('sponsorObj')) {
      sessionStorage.setItem('sponsorObj', JSON.stringify(sponsorObj));
    } else {
      console.info('Storage not supported');
    }
  } catch (e) {
    console.log('Error in Storage Sponsor', e);
  }
};
