/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import i18n from 'i18next';
import { indexOf } from 'lodash';

let nextPrevType; //nextPrevFlag,

export const PrevArrow = (props) => {
  const {
    onClick,
    currentSlide,
    className,
    slideCount,
    style,
    clicked,
    pageEditing,
    slickPrev,
  } = props;
  return (
    <React.Fragment>
      {pageEditing && slickPrev && (
        <div onClick={() => slickPrev()} className="editorSlick slick-arrow prev slider-prev"></div>
      )}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick();
          arrowEvents(e, 'prev');

          e.stopPropagation();
        }}
        onKeyPress={(e) => {
          arrowEvents(e, 'prev');
        }}
        onKeyUp={(e) => {
          arrowEvents(e, 'prev');
        }}
        aria-label={i18n.t('PreviousButton')}
        className={`slider-prev ${className} ${
          currentSlide + 1 === slideCount ? 'slides-remain' : ''
        }`}
        style={{ display: 'inline' }}
        aria-disabled={currentSlide === 0}
      >
        <i className="icon-carousel-prev"></i>
      </a>
    </React.Fragment>
  );
};

export const NextArrow = (props) => {
  const {
    onClick,
    currentSlide,
    slideCount,
    slideShow,
    className,
    style,
    clicked,
    pageEditing,
    slickNext,
  } = props;
  return (
    <React.Fragment>
      {pageEditing && slickNext && (
        <div onClick={() => slickNext()} className="editorSlick slick-arrow next slider-next"></div>
      )}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick();
          arrowEvents(e, 'next');
          e.stopPropagation();
        }}
        onKeyPress={(e) => {
          arrowEvents(e, 'next');
        }}
        onKeyUp={(e) => {
          arrowEvents(e, 'next');
        }}
        aria-label={i18n.t('NextButton')}
        className={`slider-next ${className} ${clicked && 'clicked'}`}
        style={style}
        aria-disabled={currentSlide + slideShow === slideCount}
      >
        <i className="icon-carousel-next"></i>
      </a>
    </React.Fragment>
  );
};
export const PrevArrowComingUp = (props) => {
  const {
    onClick,
    currentSlide,
    className,
    slideCount,
    style,
    clicked,
    pageEditing,
    slickPrev,
  } = props;
  return (
    <React.Fragment>
      {pageEditing && slickPrev && (
        <div
          onClick={() => slickPrev()}
          className="editorSlick slick-arrow prev slider-prev background"
        ></div>
      )}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick();
          arrowEvents(e, 'prev');

          e.stopPropagation();
        }}
        onKeyPress={(e) => {
          arrowEvents(e, 'prev');
        }}
        onKeyUp={(e) => {
          arrowEvents(e, 'prev');
        }}
        aria-label={i18n.t('PreviousButton')}
        className={`slider-prev-circle  ${className} ${
          currentSlide + 1 === slideCount ? 'slides-remain' : ''
        }`}
        style={{ display: 'inline' }}
        aria-disabled={currentSlide === 0}
      >
        <i className="icon-arrow-left-circle"></i>
      </a>
    </React.Fragment>
  );
};
export const NextArrowComingUp = (props) => {
  const {
    onClick,
    currentSlide,
    slideCount,
    slideShow,
    className,
    style,
    clicked,
    pageEditing,
    slickNext,
  } = props;
  useEffect(() => {
    if (currentSlide + 1 === slideCount) {
      document.getElementsByClassName('icon-arrow-left-circle')[0].style.opacity = 0.5;
    }
  });
  return (
    <React.Fragment>
      {pageEditing && slickNext && (
        <div onClick={() => slickNext()} className="editorSlick next slider-next background"></div>
      )}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick && onClick();
          arrowEvents(e, 'next');
          e.stopPropagation();
        }}
        onKeyPress={(e) => {
          arrowEvents(e, 'next');
        }}
        onKeyUp={(e) => {
          arrowEvents(e, 'next');
        }}
        aria-label={i18n.t('NextButton')}
        className={`slider-next-circle ${className} ${clicked && 'clicked'}`}
        style={style}
        aria-disabled={currentSlide + slideShow === slideCount}
      >
        <i className="icon-arrow-right-circle"></i>
      </a>
    </React.Fragment>
  );
};
const arrowEvents = (e, value) => {
  // nextPrevFlag = value; //Removed for achieving accessibility as per new carousel design
  nextPrevType = e.which === 13 ? true : false;
};

export const onInitAccessibility = (slideTrack, slides, type) => {
  if (slideTrack) {
    slideTrack.setAttribute('role', 'list');
    let slickCurrent = slideTrack.querySelector('.slick-current');
    if (slickCurrent) {
      slickCurrent.setAttribute('aria-hidden', 'false');
    }
  }
  if (slides) {
    slides.forEach((item) => {
      item.setAttribute('role', 'listitem');
      item.removeAttribute('tabindex');
    });
  }

  let ariaTrue = slideTrack && slideTrack.querySelectorAll('div[aria-hidden="true"]');
  if (ariaTrue) {
    if (type === 'themed') {
      socialCardAccessibility(ariaTrue);
    }
    commonAccessibility(ariaTrue);
  }
};

const socialCardAccessibility = (ariaTrue) => {
  ariaTrue.forEach((item) => {
    let isSocial = item.querySelector('.mu-item.social-card');
    if (isSocial) {
      isSocial.classList.add('hideSocialCard');
    }
  });
};

export const beforeChangeAccessbility = (slick, type, toggleState) => {
  if (slick) {
    let ariaTrue = slick.track && slick.track.querySelectorAll('div[aria-hidden="true"]');
    if (ariaTrue) {
      if (type === 'mutv') {
        ariaTrue.forEach((item) => {
          let muItemInfo = item.querySelector('div.mu-item__info');
          let headingAnchor = muItemInfo && muItemInfo.querySelector('a');
          headingAnchor && headingAnchor.setAttribute('tabindex', '0');
        });
      } else {
        ariaTrue.forEach((item) => {
          let anchor = item.querySelectorAll('a');
          if (anchor) {
            anchor.forEach((i) => {
              i.setAttribute('tabindex', '0');
            });
          }
          let tabIndex = item.querySelectorAll("[tabindex='-1']");
          if (tabIndex) {
            tabIndex.forEach((i) => {
              i.setAttribute('tabindex', '0');
            });
          }
          const noFocus = item.querySelectorAll('#destLink');
          if (noFocus && noFocus.length > 0) {
            noFocus.forEach((i) => {
              i.setAttribute('tabindex', '-1');
            });
          }
        });
      }
    }
    if (type === 'themed') {
      setTimeout(() => {
        if (slick && slick.track) {
          let ariaFalse = slick.track.querySelectorAll('div[aria-hidden="false"]');
          if (ariaFalse) {
            ariaFalse.forEach((item) => {
              let socialCard = item.querySelector('.mu-item.social-card');
              if (socialCard) {
                socialCard.classList.remove('hideSocialCard');
              }
            });
          }
          let ariaTrue = slick.track.querySelectorAll('div[aria-hidden="true"]');
          if (ariaTrue) {
            ariaTrue.forEach((item) => {
              let socialCard = item.querySelector('.mu-item.social-card');
              if (socialCard) {
                socialCard.classList.add('hideSocialCard');
              }
            });
          }
        }
      }, 100);
    }
  }
};

export const afterChangeAccessbility = (slick) => {
  if (slick.track) {
    nextPrevType ? slick.track.classList.remove('nofocus') : slick.track.classList.add('nofocus');
    let slickCurrent = slick.track.querySelector('.slick-current');
    if (slickCurrent) {
      slickCurrent.setAttribute('aria-hidden', 'false');
    }
  }
  /**
   * Removed for achieving accessibility as per new carousel design
   */
  // if (nextPrevFlag === 'next') {
  //   let slickTrack = slick.track && slick.track.querySelectorAll('.slick-active');
  //   const focusAdd = slickTrack && slickTrack[slickTrack.length - 1];
  //   if (focusAdd) {
  //     focusAdd.setAttribute('tabindex', '0');
  //     focusAdd.focus();
  //   }
  // } else if (nextPrevFlag === 'prev') {
  //   let slickTrack = slick.track && slick.track.querySelectorAll('.slick-active');
  //   const focusAdd = slickTrack && slickTrack[0];
  //   if (focusAdd) {
  //     focusAdd.setAttribute('tabindex', '0');
  //     focusAdd.focus();
  //   }
  // }

  let ariaTrue = slick.track && slick.track.querySelectorAll('div[aria-hidden="true"]');
  if (ariaTrue) {
    commonAccessibility(ariaTrue);
  }
  setTimeout(function () {
    if (slick && slick.slides) {
      slick.slides.forEach((item) => {
        item.removeAttribute('tabindex');
      });
    }
  }, 1000);
};

const commonAccessibility = (ariaTrue) => {
  ariaTrue.forEach((item) => {
    const anchor = item.querySelectorAll('a');
    if (anchor && anchor.length > 0) {
      anchor.forEach((e) => {
        e.setAttribute('tabindex', '-1');
      });
    }
    const tabIndex = item.querySelectorAll("[tabindex='0']");
    if (tabIndex && tabIndex.length > 0) {
      tabIndex.forEach((i) => {
        i.setAttribute('tabindex', '-1');
      });
    }
  });
};

export const slideShowCount = () => {
  let slideCount = 4;
  if (typeof window !== 'undefined' && window.innerWidth <= 567) {
    slideCount = 1;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 568 &&
    window.innerWidth <= 767
  ) {
    slideCount = 1;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 768 &&
    window.innerWidth <= 1023
  ) {
    slideCount = 2;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 1024 &&
    window.innerWidth <= 1279
  ) {
    slideCount = 3;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 1280 &&
    window.innerWidth < 1700
  ) {
    slideCount = 4;
  } else {
    slideCount = 5;
  }
  return slideCount;
};

export const featuredShowCount = (featurecardType) => {
  if (featurecardType) {
    let slideCount = 4;
    if (typeof window !== 'undefined' && window.innerWidth <= 567) {
      slideCount = 1;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 568 &&
      window.innerWidth < 1024
    ) {
      slideCount = 2;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 1024 &&
      window.innerWidth < 1280
    ) {
      slideCount = 3;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 1280 &&
      window.innerWidth < 1440
    ) {
      if (featurecardType === 'Audio') {
        slideCount = 3;
      } else {
        slideCount = 4;
      }
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 1440 &&
      window.innerWidth < 1920
    ) {
      if (featurecardType === 'Audio') {
        slideCount = 3;
      } else {
        slideCount = 4;
      }
    } else {
      if (featurecardType === 'Audio') {
        slideCount = 4;
      } else {
        slideCount = 5;
      }
    }
    return slideCount;
  }
};
