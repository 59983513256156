/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import moment from 'moment';
import { createDestinationUrl } from '../../utils/utils';

const LeagueTable = ({ t, item, groupItem, tableType, groupName }) => {
  const {
    position_t,
    team_t,
    gamesplayed_t,
    wins_t,
    draws_t,
    lost_t,
    goalsfor_t,
    goalsagainst_t,
    goalsdifference_t,
    teampoints_t,
    last5games_t,
    // contenttype_t,
    __published_tdt,
    // itemid_s,
    optacontent,
    destinationurl_s,
    religation_tl,
  } = item;

  const headerData = {
    position_t,
    team_t,
    gamesplayed_t,
    wins_t,
    draws_t,
    lost_t,
    goalsfor_t,
    goalsagainst_t,
    goalsdifference_t,
    teampoints_t,
    last5games_t,
  };
  const { value } = optacontent;

  const religateRow = (i) => {
    let isReligationRow = false;
    if (religation_tl) {
      isReligationRow = i >= groupItem.length - religation_tl;
    }
    return isReligationRow;
  };
  return (
    <div className="live-table__table">
      <table>
        <caption>
          {tableType === 'fullTable' && groupName && (
            <div className="live-table__group">
              <h2>{t(groupName)}</h2>
            </div>
          )}
          <div className="live-table__details">
            {tableType !== 'fullTable' && groupName && <h2>{t(groupName)}</h2>}
            {value[0].TimeStamp && (
              <p>Last updated {moment(value[0].TimeStamp).format('MMM D YYYY [at] HH:mm')}</p>
            )}
          </div>
        </caption>
        <tbody>
          <TableHeader headerValue={headerData} />
          {groupItem.map((rowItem, i) => (
            <TableRow
              dataItem={rowItem}
              isGrouping={groupName}
              key={i}
              index={i}
              religationRow={religateRow(i)}
            />
          ))}
        </tbody>
      </table>
      <div className="white-btn-skew">
        <a href={createDestinationUrl(destinationurl_s)} aria-describedby={item.itemid_s}>
          <span>{t('Viewall')}</span>
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(LeagueTable);
